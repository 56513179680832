import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { BsSearch } from "react-icons/bs";
import { getArticles, populateCategorie } from "../../api";
import { baseUrl } from "../../utils/constant_api";
import { FaEdit, FaTimes } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import Popup from "../../components/Popup/Popup";

const BlogEditor = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSecteur, setSelectedSecteur] = useState(null);
  const [input, setInput] = useState(""); 
  const [selectedImages, setSelectedImages] = useState([]);
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleChange = (value) => {
    setInput(value);
    if (value.trim().length > 0) {
      fetchData(value);
    } else {
      setCategories([]); // Cache les résultats si l'utilisateur supprime tout
    }
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const fetchData = (value) => {
    fetch(`${baseUrl}/categories`)
      .then((response) => response.json())
      .then((json) => {
        const searchResults = json.filter(
          (categorie) =>
            value &&
            categorie?.name &&
            removeAccents(categorie.name.toLowerCase()).includes(
              removeAccents(value.toLowerCase())
            )
        );
        setCategories(searchResults);
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des catégories :", error)
      );
  };

  // Sélectionner une catégorie
  const handleCategorySelect = (categorie) => {
    setSelectedCategory(categorie);
    setSelectedSecteur(null); // Réinitialiser le secteur si une catégorie est sélectionnée
    setInput(""); // Réinitialiser le champ de recherche
    setCategories([]); // Cacher la liste après la sélection
  };

  // Sélectionner un secteur
  const handleSecteurSelect = (categorie, secteur) => {
    setSelectedCategory(categorie);
    setSelectedSecteur(secteur);
    setInput(""); // Réinitialiser le champ de recherche
    setCategories([]); // Cacher la liste après la sélection
  };
  const removeImage = (index) => {
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!title || !content || !selectedCategory) {
      alert("Veuillez remplir tous les champs et sélectionner une catégorie.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", content);
    formData.append(" categoryId", selectedCategory._id); // Envoi uniquement de la catégorie

    try {
      await axios
        .post(`${baseUrl}/blog/articles`, formData, {
          headers: { 
            "Content-Type": "application/json",
            "x-access-token": `${token}`,

          },
        })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            const newFormData = new FormData();
            for (let i = 0; i < selectedImages?.length; i++) {
              newFormData.append(`images`, selectedImages[i]);
            }
            axios
              .post(
                `${baseUrl}/blog/articles/${res.data.article._id}/images`,
                newFormData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                if (res.status === 201 || res.status === 200) {
                  setIsPopupVisible(true)
                  setPopupMessage("article ajouter avec success");
                }
              });
          }
        });
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'article", error);
    }
  };
  
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${baseUrl}/blog/articles/${id}`);
      alert("Article supprimé avec succès");
      getArticles(setArticles);
    } catch (error) {
      console.error("Erreur lors de la suppression", error);
    }
  };

  const stripHtmlTags = (text) => {
    return text.replace(/<[^>]*>/g, "");
  };

  const onClose = () => {
    setIsPopupVisible(false)
    window.location.reload();
  }

  useEffect(() => {
    populateCategorie(setCategories);
    getArticles(setArticles);
  }, []);
  
  return (
    <>
   
      <div className="p-4 max-w-2xl mx-auto bg-white shadow-lg rounded-lg mt-4">
        <h2 className="text-xl font-bold mb-4">Éditeur de Blog</h2>

        {/* Barre de recherche */}
        <div className="flex border mb-3 bg-white rounded-xl items-center px-2 py-1 mt-8 justify-around">
          <BsSearch color="var(--blue)" size={25} />
          <input
            type="text"
            className="border-none outline-none lg:w-[75%] text-black"
            placeholder="Recherchez une catégorie..."
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>

        {/* Liste des catégories et secteurs (affichée uniquement si l'utilisateur tape) */}
        {input.trim() && categories.length > 0 && (
          <div className="results-list bg-white shadow-md rounded-lg p-2 mt-2">
            {categories.map((categorie, id) => (
              <div key={id} className="border-b p-2">
                {/* Sélection de la catégorie */}
                <p
                  className="font-medium cursor-pointer hover:bg-gray-200 p-1 rounded-md"
                  onClick={() => handleCategorySelect(categorie)}
                >
                  {categorie.name}
                </p>

                {/* Liste des secteurs (sous-catégories) */}
                {categorie.secteurs?.length > 0 && (
                  <div className="pl-4 mt-1">
                    {categorie.secteurs.map((secteur, index) => (
                      <p
                        key={index}
                        className="text-sm text-gray-700 cursor-pointer hover:bg-gray-100 p-1 rounded-md"
                        onClick={() => handleSecteurSelect(categorie, secteur)}
                      >
                        {secteur.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Affichage de la sélection */}
        {(selectedCategory || selectedSecteur) && (
          <div className="mt-4 p-2 bg-green-100 text-green-800 rounded-md">
            Catégorie sélectionnée : <strong>{selectedCategory.name}</strong>
            {selectedSecteur && (
              <>
                {" > "}
                <strong>{selectedSecteur.name}</strong>
              </>
            )}
          </div>
        )}

        {/* Formulaire */}
        <input
          type="text"
          placeholder="Titre de l'article"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded-md mb-2"
        />

        <input
          type="file"
          multiple
          onChange={handleImageChange}
          className="mb-2"
        />

        {selectedImages.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-2">
            {selectedImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index}`}
                  className="w-20 h-20 object-cover rounded-md"
                />
                <button
                  className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full p-1"
                  onClick={() => removeImage(index)}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        )}

        <ReactQuill
          value={content}
          onChange={setContent}
          className="mb-4 bg-gray-100"
        />

        <button
          onClick={handleSubmit}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Soumettre l'article
        </button>
      </div>

      <div className="mt-6 p-4 mx-auto bg-white shadow-lg rounded-lg">
      <div className="p-4 max-w-2xl mx-auto bg-white shadow-md rounded-lg mt-4">
        <input
          type="text"
          placeholder="Rechercher un article..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded-md"
        />
      </div>
        <h2 className="text-xl font-bold mb-4">Articles existants</h2>
        {articles.length === 0 ? (
          <p className="text-gray-500">Aucun article disponible.</p>
        ) : (
          articles
            .filter((article) =>
              article.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((article) => (
              <div key={article._id} className="border-b p-3 flex justify-between items-center">
                <div>
                  <h3 className="font-bold">{article.title}</h3>
                  <p className="text-sm text-gray-600">{stripHtmlTags(article.description).slice(0, 100)}...</p>
                </div>
                <div className="flex space-x-2">
                  <button className="bg-yellow-500 text-white p-2 rounded-md">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(article._id)} className="bg-red-500 text-white p-2 rounded-md">
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))
        )}
      </div>

      {isPopupVisible && (
        <Popup message={popupMessage} onClose={() => onClose()} />
      )}

    </>
  );
};

export default BlogEditor;
