import React from "react";
import Hero from "../components/Hero/Hero";
import TopCategories from "../components/TopCategories/TopCategories";
import WhyUs from "../components/WhyUs/WhyUs";
import BlogSection from "../components/Blog/blogSection";
import RenovationGuideOffer from "../components/RenovationGuideOffer/RenovationGuideOffer";
const Home = ({categories}) => {
  return (
    <div>
     <Hero categories = {categories}/>
     <TopCategories categories = {categories}/>
     <WhyUs/>
     <RenovationGuideOffer/>
     <BlogSection/>
    </div>
  );
};

export default Home;
