import React from 'react'
import { RiArrowRightCircleFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const WhyUs = () => {
  return (
    <div className='w-full mt-6'>
      <div className=" innerWidth  lg:flex gap-8 mx-auto ">
        {/* left side  */}
        <div className=" w-full h-full rounded-lg lg:flex">
          <img
            src="../../images/service2.jpg"
            alt=""
            className="w-full mx-auto rounded-lg object-cover"
          />
        </div>

        <div className="w-full">
            <h1 className='text-xl mt-2 lg:mt-2 font-bold leading-snug md:text-2xl text-[var(--black)]' >
                Vous avez besoin de chantiers ? xxl-connexion est là pour vous !
            </h1>
            <h2 className='text-lg lg:text-xl mt-2 font-semibold leading-snung  text-[var(--black)] '>
             xxl-connexion vous offre l'opportunité de développer votre activité en trouvant facilement de nouveaux chantiers.
            </h2>
            <p className='py-4 text-[var(--gray)] text-justify text-lg'>
              XXL-CONNEXION  est la plateforme idéale pour trouver des chantiers adaptés à votre profil. L'inscription est gratuite et vous permet de recevoir régulièrement des propositions de projets situés près de chez vous et correspondant à vos compétences. Vous avez la liberté de choisir les chantiers qui vous intéressent, qu'ils soient de petite ou de grande envergure.
            </p>

            <div className='flex gap-2 items-center mt-2'>
                <RiArrowRightCircleFill size='25'/>

                <Link className='text-sm lg:text-lg text-[var(--red)]'
                to='register'
                >
                  Professionnels, Rejoignez-nous dès maintenant !
                </Link>
            </div>

        </div>

      </div>
    </div>
  )
}

export default WhyUs
