import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";
import { BsSearch } from "react-icons/bs";
import { baseUrl } from "../../utils/constant_api";
import { FaCircleArrowRight } from "react-icons/fa6";

const Hero = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [input, setInput] = useState("");

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Supprime les accents
  };

  const fetchData = (value) => {
    fetch(`${baseUrl}/categories`)
      .then((response) => response.json())
      .then((json) => {
        const searchResults = json.filter((categorie) => {
          return (
            value &&
            categorie &&
            categorie.name &&
            removeAccents(categorie.name.toLowerCase()).includes(removeAccents(value.toLowerCase())) // Insensible à la casse et aux accents
          );
        });
        setCategories(searchResults);
      });
  };
    

  const goToCreateProject = (categorie, secteur) => {
    localStorage.setItem("cat_project", categorie._id);
    if (secteur) {
      navigate(`./pubprojet/${categorie.name}/${secteur.name}`);
      localStorage.setItem("sec_project", secteur._id);
    } else {
      navigate(`./pubprojet/${categorie.name}/xxl-connexion`);
    }
  };

 
  

  return (
    <section className=" text-white relative pb-8 bg-[var(--black)] bg-center ">
      <div className="paddings innerWidth  flex gap-8 mx-auto">
        {/*left side*/}
        <div className="mt-8 w-full">
          <div className="flex gap-4 items-center">
            <FaCircleArrowRight
              color="red"
              size="25px"
              className="bg-white rounded-full"
            />
            <h2
              className="hover:underline cursor-pointer"
              onClick={() => navigate("register")}
            >
              Professionel ? c'est ici !
            </h2>
          </div>
          <div className="text-2xl py-6 lg:mt-4 md:text-[2.3rem] font-bold">
            <h1>Comparez les artisans et trouvez celui qui vous correspond.</h1>
          </div>

          <div className=" w-full rounded-lg  lg:hidden">
            <img
              src="images/214.jpg"
              alt=""
              className="w-full mx-auto rounded-lg object-cover"
            />
          </div>

          <div className="flexColStart mt-4">
            <h2>Publiez votre projet !</h2>
          </div>

          <div className="flex bg-white rounded-xl items-center px-2 py-1 mt-8 justify-around">
            <BsSearch color="var(--blue)" size={25} className="" />
            <input
              className="border-none outline-none lg:w-[75%] text-black"
              type="text"
              placeholder="(exemple) : élctricité"
              value={input}
              onChange={(e) => handleChange(e.target.value)}
            />
            <button className="button">Go !</button>
          </div>
          {categories?.length > 0 && (
            <div className="results-list">
              {categories?.map((categorie, id) => {
                return (
                  <div key={id} className="result-items">
                    <p onClick={() => goToCreateProject(categorie)}>
                      {categorie.name}
                    </p>
                    {categorie.secteurs?.map((secteur, index) => {
                      return (
                        <p
                          key={index}
                          className="mx-3 text-sm py-1"
                          onClick={() => goToCreateProject(categorie, secteur)}
                        >
                          {secteur.name}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/*rigth side*/}

        <div className=" w-full h-full rounded-lg hidden lg:flex">
          <img
            src="images/214.jpg"
            alt=""
            className="w-full mx-auto rounded-lg object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
