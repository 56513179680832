import "./CategoriesPage.css";
import AdminSidebar from "../../../components/AdminDashboard/AdminSidebar/AdminSidebar";
import AdminNavbar from "../../../components/AdminDashboard/AdminNavbar/AdminNavbar";
import List from "../../../components/AdminDashboard/table/Table";
import React, { useState } from "react";
import CategoriesTable from "../../../components/AdminDashboard/AdminCategories/CategoriesTable";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";
import { baseUrl } from "../../../utils/constant_api";
import { CircularProgress } from "@mui/material";

const CategoriesPage = ({ categories }, { authUser }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCategorieCreate = (event) => {
    setLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .post(
        `${baseUrl}/categories/new`,
        { name, description },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setOpen(false);
          window.location.reload();
        } else {
          setLoading(false)
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setError(error?.response.data);
      });
  };
  return (
    <div className="">
        <div className="categorie innerWidth paddings flexCenter">
          <button className="button" onClick={handleClickOpen}>
            Créer une categorie
          </button>
          <Dialog open={open} onClose={handleClose}>
            <div className="flex justify-center py-4">
              <h1 className="font-semibold text-xl text-gray-500">
                Créer une nouvelle catégorie
              </h1>
              <h1 className="text-red-600 text-lg mt-2">{errorMessage}</h1>
            </div>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                value={name}
                onChange={handleNameChange}
                label="Nom de la categorie"
                type="text"
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                margin="dense"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                label="Description"
                type="text"
                fullWidth
                variant="standard"
              />
            </DialogContent>

            {isLoading ? (
              <div className="flex p-4 justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="p-2 flex justify-center gap-2">
                <button className="border bg-black py-2 text-white px-4 rounded-md"
                  onClick={handleClose}
                >
                  Annuler
                </button>
                <button className="bg-red-700 py-2 text-white px-4 rounded-md"
                  onClick={handleCategorieCreate}
                >
                  Ajouter
                </button>
              </div>
            )}
          </Dialog>
        </div>
        <CategoriesTable categories={categories} />
    </div>
  );
};

export default CategoriesPage;
