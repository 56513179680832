import React, { useEffect, useState } from "react";
import { getArticles } from "../../api";

const BlogSidebar = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const goToDetails = (id) => {
    window.location.href= `/blog/article/${id}`
  }

  useEffect(() => {
    getArticles(setArticles);
  }, []);
  return (
    <div className="bg-gray-100 w-full p-2">
      <div className="p-2 mb-4 max-w-2xl mx-auto bg-white shadow-md rounded-lg mt-4">
        <input
          type="text"
          placeholder="Rechercher un article..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded-md"
        />
      </div>
      <div className="">

      </div>
      {articles.length === 0 ? (
        <p className="text-gray-500">Aucun article disponible.</p>
      ) : (
        articles
          .filter((article) =>
            article.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((article) => (
            <div
              key={article._id}
              className="border-b p-3 flex justify-between items-center"
            >
              <div>
                <h3 className="font-medium cursor-pointer"
                onClick={() => goToDetails(article._id)}
                >{article.title}</h3>
              </div>
            </div>
          ))
      )}
    </div>
  );
};

export default BlogSidebar;
