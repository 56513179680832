import React from "react";
import "./AdminHome.css";
import Widget from "../../../components/AdminDashboard/Widget/Widget";
import Featured from "../../../components/AdminDashboard/Featured/Featured";
import Chart from "../../../components/AdminDashboard/Charts/Charts";
import Datatable from "../../../components/AdminDashboard/Datatable/Datatable";

const AdminHome = () => {
  return (
    <div className="">
      <div className="widgets">
        <Widget type="user" />
        <Widget type="artisans" />
        <Widget type="projets" />
        <Widget type="paiements" />
      </div>
      <div className="charts">
        <Featured />
        <Chart aspect={2 / 1} title="chiffre d'affaires des 6 derniers mois" />
      </div>
      <div className="listContainer">
        <div className="listTitle">Dernières transactions</div>
        <Datatable/>
      </div>
    </div>
  );
};

export default AdminHome;
