import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

const Blogcard = ({ article }) => {

  const stripHtmlTags = (text) => {
    return text.replace(/<[^>]*>/g, "");
  };

  const goToDetails = (id) => {
    window.location.href= `blog/article/${id}`
  }
  return (
    <div key={article._id} className="border shadow-lg items-center">
      <div className="w-full object-center rounded-md">
        <img src={article.images[0]} alt="" className="w-full max-h-52"/>
      </div>
      <div className="p-3">
        <h3 className="font-bold mb-2 text-lg">{article?.title.slice(0, 35)}...</h3>
        <p className="text-sm text-gray-600 mb-3 text-justify">{stripHtmlTags(article?.description).slice(0, 100)}...</p>
        <button className ="w-full bg-red-800 text-white py-2 mt-2" 
        onClick={() => goToDetails(article._id)}
        >Lire plus</button>
      </div>
     
    </div>
  );
};

export default Blogcard;
