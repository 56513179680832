import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";
import axios from 'axios';
import { baseUrl } from "../../utils/constant_api";

const StripeContainer = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    axios.get(`${baseUrl}/payment/config`).then((response) => {
      setStripePromise(loadStripe(response.data.pubKey));
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.post(`${baseUrl}/payment/create-payment-intent`, {}, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    }).then((response) => {
      setClientSecret(response.data.clientSecret);
    });
  }, []);

  return (
    <div>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <StripeForm />
        </Elements>
      )}
    </div>
  );
};

export default StripeContainer;
