import React, { useEffect } from 'react'
import Blogcard from './Blogcard'
import { useState } from 'react'
import { getArticles } from '../../api'

const BlogSection = () => {
    const [articles, setArticles] = useState([])


    useEffect(() => {
        getArticles(setArticles)
    }, [setArticles])

    console.log(articles)
  return (
    <div>
      <div className="b-wrapper d-flex mt-4">
      <div className="innerWidth paddings b-container">
        <div className="t-head flexColStart">
          <span className="primaryText">Bon à savoir</span>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-2 lg:grid-cols-3 md:gap-8">
            {articles.map(article => (
                <Blogcard key={article._id} article ={article}/>
            ))}
        </div>

      </div>
    </div>
    </div>
  )
}

export default BlogSection
