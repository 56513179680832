import {Outlet} from "react-router-dom";
import React from 'react'
import "./AdminHome.css";
import AdminSidebar from '../AdminSidebar/AdminSidebar';
import AdminNavbar from '../AdminNavbar/AdminNavbar';


const AdminLayout = () => {
  return (
    <div className="admin-home">
      <div className="sideBar">
        <AdminSidebar />
      </div>
      <div className="homeContainer">
        <AdminNavbar />
        <Outlet/>
      </div>

    </div>
  )
}

export default AdminLayout
