import React from "react";
import "./AdminNavbar.css";
import SearchIcon from "@mui/icons-material/Search";
import LanguageIcon from "@mui/icons-material/Language";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ListIcon from '@mui/icons-material/List';
import avatar from "../../../img/user1.png"

const AdminNavbar = () => {
  return (
    <div className="admin-navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Recherche..." />
          <SearchIcon />
        </div>
        <div className="items">
          <div className="item">
            <FullscreenExitIcon className="icon"/>
          </div>
          <div className="item">
            <NotificationsNoneIcon className="icon"/>
            <div className="counter">8</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineIcon className="icon"/>
            <div className="counter">4</div>
          </div>
          <div className="item" >
            <ListIcon className="icon"/>
          </div>
          <div className="item" >
            <img src={avatar} alt="" className="avatar"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
