import React from "react";
import "./AdminSidebar.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PaymentIcon from "@mui/icons-material/Payment";
import EuroIcon from "@mui/icons-material/Euro";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LanIcon from "@mui/icons-material/Lan";
import { PiNotePencilBold } from "react-icons/pi";

import { Link } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar fixed p-3">
      <div className="top mt-0">
        <Link to="/">
          <span className="adminlogo">xxl-connect</span>
        </Link>
      </div>
      <hr />

      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/admin">
            <li>
              <DashboardIcon className="mr-3" size={20} color="error" />
              <span>Dashboard </span>
            </li>
          </Link>

          <Link to="/admin/users">
            <li>
              <AccountCircleIcon className="mr-3" size={20} color="error" />
              <span>Utulisateurs</span>
            </li>
          </Link>

          <Link to="edit-blog">
            <li className="flex items-center">
              <PiNotePencilBold className="mr-3" size={20} color="red" />
              <span>Blog</span>
            </li>
          </Link>

          <p className="title">PROFESSIONEL</p>
          <Link to = "/admin/categories">
            <li>
              <LanIcon className="mr-3" size={20} color="error" />
              <span>catégories</span>
            </li>
          </Link>

          <Link>
            <li>
              <SupervisedUserCircleIcon className="mr-3" size={20} color="error" />
              <span>Artisans</span>
            </li>
          </Link>

          <Link>
            <li>
              <AssuredWorkloadIcon className="mr-3" size={20} color="error" />
              <span>Projets</span>
            </li>
          </Link>

          <p className="title">FINANCES</p>
          <Link>
            <li>
              <LocalGroceryStoreIcon className="mr-3" size={20} color="error" />
              <span>Boutique</span>
            </li>
          </Link>

          <Link>
            <li>
              <PaymentIcon className="mr-3" size={20} color="error" />
              <span>Paiments</span>
            </li>
          </Link>

          <Link>
            <li>
              <EuroIcon className="mr-3" size={20} color="error" />
              <span>Facturation</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        <div className="colorOption"></div>
        <div className="colorOption"></div>
      </div>
    </div>
  );
};

export default AdminSidebar;
