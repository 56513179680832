import React, { useEffect, useState } from "react";
import "./List.css";
import Datatable from "../../../components/AdminDashboard/Datatable/Datatable";

const List = () => {
  return (
    <div className="">
      <Datatable />
    </div>
  );
};

export default List;
