import React from "react";
import "./Single.css";
import user from "../../../img/user1.png";
import List from "../../../components/AdminDashboard/table/Table";

const Single = () => {
  return (
    <div className="">
        <div className="signleTop">
          <div className="singleLeft">
            <div className="editButton">edit</div>
            <h1 className="singleText">Informations</h1>
            <div className="singleItem">
              <img src={user} alt="" className="itemImg" />
              <div className="singleDetail">
                <h1 className="itemTitle">Jane Doe</h1>
                <div className="detialItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">janedoe@gmail.com</span>
                </div>
                <div className="detialItem">
                  <span className="itemKey">Telephone:</span>
                  <span className="itemValue">06 789 654 33</span>
                </div>
                <div className="detialItem">
                  <span className="itemKey">Adresse:</span>
                  <span className="itemValue">
                   15 Rue des chévaliers, 66000 perpignan
                  </span>
                </div>
                <div className="detialItem">
                  <span className="itemKey">Profil: </span>
                  <span className="itemValue">Artisan</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signleBottom">
        <h1 className="singleText">Dernières transactions</h1>
          <List/>
        </div>
    </div>
  );
};

export default Single;
