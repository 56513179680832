import React, { useEffect, useState } from "react";
import "./Datatable.css";
import { Link } from "react-router-dom";
import { getUsers } from "../../../api";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { baseUrl } from "../../../utils/constant_api";
import axios from "axios";

const validRoles = ["root", "admin", "agent", "artisan", "client", "editeur"];

const Datatable = () => {
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    userId: null,
  });

  const updateUserRole = async (userId, newRole) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.put(
        `${baseUrl}/auth/update-role/${userId}`,
        { newRole },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      );
      if (response.status !== 200 || response.status !== 201)
        throw new Error("Échec de la mise à jour du rôle");
      setNotification({
        open: true,
        message: "Rôle mis à jour avec succès",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const viewUserProfile = (userId) => {
    window.location.href = `users/profile/${userId}`; // Redirige vers la page du profil utilisateur
  };

  const confirmDeleteUser = (userId) => {
    setDeleteDialog({ open: true, userId });
  };

  const deleteUser = async () => {
    try {
      await axios.delete(`/api/users/${deleteDialog.userId}`);
      setUsers(users.filter((user) => user.id !== deleteDialog.userId));
      setNotification({
        open: true,
        message: "Utilisateur supprimé avec succès",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: "Échec de la suppression de l'utilisateur",
        severity: "error",
      });
    }
    setDeleteDialog({ open: false, userId: null });
  };
  useEffect(() => {
    getUsers(setUsers);
  }, [setUsers]);

  return (
    <>
      <div className="p-6 shadow-lg bg-white rounded-lg">
        <h2 className="text-xl font-semibold mb-4">Liste des utilisateurs</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">Nom</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Rôle</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} className="border-t">
                  <td className="px-4 py-2">{user?.nom}</td>
                  <td className="px-4 py-2">{user.email}</td>
                  <td className="px-4 py-2">
                    <select
                      className="border rounded px-2 py-1"
                      value={user.role}
                      onChange={(e) => updateUserRole(user.id, e.target.value)}
                    >
                      {validRoles.map((role) => (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="px-4 py-2 flex gap-2">
                    <button
                      className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                      onClick={() => updateUserRole(user.id, user.role)}
                    >
                      Confirmer
                    </button>
                    <button
                      className="bg-green-500 text-white p-2 rounded hover:bg-green-600"
                      onClick={() => viewUserProfile(user.id)}
                    >
                      <AiOutlineEye size={20} />
                    </button>
                    <button
                      className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                      onClick={() => confirmDeleteUser(user.id)}
                    >
                      <AiOutlineDelete size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Snackbar
          open={notification.open}
          autoHideDuration={3000}
          onClose={() => setNotification({ ...notification, open: false })}
        >
          <Alert
            onClose={() => setNotification({ ...notification, open: false })}
            severity={notification.severity}
          >
            {notification.message}
          </Alert>
        </Snackbar>

        <Dialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({ open: false, userId: null })}
        >
          <DialogTitle>Confirmer la suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action
            est irréversible.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteDialog({ open: false, userId: null })}
              color="primary"
            >
              Annuler
            </Button>
            <Button onClick={deleteUser} color="secondary">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Datatable;
