import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import ReactQuill from "react-quill";

const ArticlesDetails = () => {
  const { id } = useParams();
  const [article, setArticle] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/blog/articles/${id}`, {
          signal: controller.signal,
        });
        setArticle(response.data.article);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Erreur lors de la récupération de l'article :", error);
        }
      }
    };

    fetchData();

    return () => controller.abort();
  }, [id]);

  return (
    <div>
      <div className="relative w-full h-52">
        {/* Superposition avec un dégradé gris */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-500/30"></div>

        {/* Image d'arrière-plan */}
        <div className="w-full h-52 bg-[url(images/service1.jpg)] bg-cover bg-center"></div>
      </div>

      <div className="md:flex w-full innerWidth">
        <div className="w-full md:w-3/4">
          <div className="p-4 mx-auto">
            <h1 className="text-2xl font-bold mb-4">
              {article?.title || "Chargement..."}
            </h1>
            <div className="rounded-lg overflow-hidden">
              <img
                src={article?.images || "/default-image.jpg"}
                alt="Illustration"
                className="w-full h-auto max-h-96 object-cover"
              />
            </div>
            <p className="text-sm text-gray-600">
              {article?.createdAt
                ? new Date(article.createdAt).toLocaleDateString()
                : "Date inconnue"}
            </p>
            <ReactQuill
              value={article?.description || ""}
              readOnly={true}
              theme="bubble"
              className="mt-4"
            />
          </div>
        </div>
        <div className="hidden md:flex md:w-1/4">
          <BlogSidebar />
        </div>
      </div>
    </div>
  );
};

export default ArticlesDetails;
