import { useState } from "react";

export default function RenovationGuideOffer() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Envoyer l'email à votre backend ou service d'emailing
    setSubmitted(true);
  };

  return (
    <div className="innerWidth mx-auto mt-4 ">
        <div className="bg-gray-100 py-12 px-6 rounded-2xl shadow-lg  mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
            🏡 Les 10 erreurs à éviter lors d'une rénovation 🏡
            </h2>
            <p className="text-gray-600 mb-6">
            Rénover son bien est une belle aventure… mais aussi un parcours semé d'embûches ! Découvrez les erreurs les plus courantes et comment les éviter pour réussir votre projet <b> sans stress et sans mauvaises surprises</b>.
            </p>

            <ul className="text-gray-700 mb-6 list-disc list-inside text-left justify-center mx-auto">
            <li>✔️ Les pièges qui font exploser le budget</li>
            <li>✔️ Les erreurs qui rallongent les délais</li>
            <li>✔️ Comment choisir les bons artisans et matériaux</li>
            <li>✔️ Les astuces pour éviter les mauvaises surprises</li>
            </ul>

            {submitted ? (
            <p className="text-green-600 font-semibold">
                ✅ Merci ! Votre guide vous a été envoyé par e-mail.
            </p>
            ) : (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <input
                type="email"
                placeholder="Entrez votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="pl-4 pr-4 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg">
                📩 Recevoir mon guide
                </button>
            </form>
            )}
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0 flex justify-center">
            <img
            src="/images/r3.png"
            alt="Guide rénovation"
            className="w-full max-w-sm rounded-lg shadow-md"
            />
        </div>
        </div>
    </div>
  );
}