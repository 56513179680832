import axios from "axios";
import { baseUrl } from "./utils/constant_api";

export const token = localStorage.getItem('token');

export const getMe = async (setAuthUser) =>{
    if (token) {
      await axios.get(`${baseUrl}/auth/me`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      })
      .then((response) => {
      if (response.data.status === "ok") {
        setAuthUser(response.data.user);
        localStorage.setItem('userRole', response.data.user.role)
      }else{
        localStorage.removeItem('token');
      }
    }).catch((error) => {
      localStorage.removeItem('token');
      window.location.href="/";
    })
    }
  }

 export const populateCategorie = async (setCategorie) => {
     await axios.get(`${baseUrl}/categories`)
    .then((res) => 
      {
        setCategorie(res.data)
      }
    );
  }

  export const getUsers = async(setUsers) => {
    await axios.get(`${baseUrl}/auth/users`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
    .then((res) => {
      setUsers(res.data.users)
    })
  }

  export const getArticles = async(setArticles) => {
    axios.get(`${baseUrl}/blog/articles`)
    .then((res) => {
      setArticles(res.data.articles)
    })
    .catch((error) => {
      console.log(error)
    })
  }

